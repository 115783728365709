import React from 'react';
import { FirebaseAuthContext } from './FirebaseAuthProvider';

const withFirebase = (WrapperComponent) => class FirebaseContext extends React.Component<any, any> {   
    render(): React.ReactNode {
        return (
            <FirebaseAuthContext.Consumer>
              { context => <WrapperComponent fb={context} {...this.props} /> }
            </FirebaseAuthContext.Consumer>
        );
    }
};

export default withFirebase;

import React from 'react'
import { Collection } from 'firestorter';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';
import withFirebase from '../../WithFirebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import moment from 'moment';

const styles = theme => ({
  content: {
    textDecoration: 'none',
    marginLeft: 20,
    display: 'grid',
    gridAutoFlow: 'row',
    width: 'auto',
    gridGap: '3px',
    overflowWrap: 'break-word'
  },
  avatars: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'start'
  },
  progress: {
    margin: theme.spacing.unit * 2,
    display: 'grid',
    // margin: '16px auto',
    'z-index': 100
  },
  players: {
    width: '20px',
    height: '20px'
  },
  chip: {
    margin: theme.spacing.unit * 2,
    fontSize: '16px'
  },
  chipSpacing: {
    paddingLeft: '5px'
  },
  formatTimestamp: {
    fontSize: 'smaller',
    padding: 0,
    color: theme.palette.secondary.main
  },
  plusmore: {
    fontSize: 'x-small',
    color: 'orange'
  }
});

const bets = new Collection('bets');

const BetList = observer(class Activities extends React.Component {
  
  state = {
    value: 2 // initial tab menu index to load
  }

  constructor(props) {
    super(props);
  }

  getBetsByUser(user) {
    bets.query = undefined;
    bets.query = (ref) => ref.where('owner','==', user.email || user.displayName);
  }

  getOldBetsByUser(user) {
    bets.query = undefined;
    bets.query = (ref) => ref.where('owner','==', user.email || user.displayName)
      .where('expiresAt','<', moment().utc().format()); 
  }

  getLiveBets() {
    bets.query = undefined;
    bets.query = ref => ref.where('expiresAt','>=', moment.utc().format());
  }

  getAllBets() {
    bets.query = undefined;
  }
 
  handleChange = (event, value) => {
    const { currentTarget } = event;
    
    // value over tabIndex, which is always zero
    switch(value) {
      case 0: this.getAllBets(); break;
      case 1: this.getLiveBets(); break;
      case 2: this.getBetsByUser(JSON.parse(currentTarget.getAttribute('data-user'))); break;
      default:
    }
    this.setState({ value });
  };

  componentDidMount() {
    // load this cache into the mix
    const { fb : { user } } = this.props;
    this.getBetsByUser(user);
  }

  render() {
    const { classes, fb : { user } } = this.props;
    const { docs, isLoading } = bets;
    const { value } = this.state;

    return <div>
      <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange} >
            <Tab label="All Bets" />
            <Tab label="Open Bets" />
            <Tab label="My Bets" data-user={JSON.stringify(user)} />
          </Tabs>
      </AppBar>
      {/* <Button className={classes.showBtn} color="secondary" onClick={this.getOldBetsByUser.bind(this, user)}>Me</Button> */}
    
      <List component="nav">
      { isLoading ? <CircularProgress className={classes.progress} color="secondary" /> :
        docs.map((doc) =>
          <div key={doc.id}> 
            <ListItem button>
              <Avatar src={doc.data && doc.data.avatar} />
              <Link to={`bet/${doc.id}`} className={classes.content}>
                <ListItemText primary={doc.data && doc.data.metadata && doc.data.metadata.title} secondary={doc.data && doc.data.details}/>
                <div className={classes.avatars}>
                {
                  doc.data && doc.data.players && doc.data.players.slice(0,9).map((player, index) => (
                  <ListItemAvatar children={<Avatar src={player.photoURL} classes={{ root: classes.players }} />} key={index} />
                  ))
                }
                <span className={classes.plusmore}>
                {
                    doc.data && doc.data.players && doc.data.players.length > 7 ? ' +'.concat(doc.data.players.length) : ''
                }
                </span>                
                </div>                
                <ListItemText classes={{ root: classes.formatTimestamp}}
                  //  primary={`created ${moment(moment.utc(doc.data.createdAt.seconds * 1000).format('LLLL')).fromNow()}`}
                  primary={`close${moment(doc.data.expiresAt).isBefore(moment()) ? 'd ': ' '}${moment(doc.data && doc.data.expiresAt).fromNow()}`}
                   disableTypography={true}
                />
              </Link>              
              <ListItemSecondaryAction>
                  <Chip label={doc.data && doc.data.wager} size="small" icon={<AttachMoneyIcon />} classes={{ root: classes.chip, label: classes.chipSpacing }} clickable variant="outlined" color="secondary" />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </div>
          )}
      </List> 
      </div>
  }

});

export default withFirebase(withStyles(styles, { theme: true })(BetList));

import React from 'react';
import { observer } from 'mobx-react';
import * as HelperUtil from '../../helpers/HelperUtil';
import CircularProgress from '@material-ui/core/CircularProgress';
import withFirebase from '../../WithFirebase';
import firebase from '../../Firebase';
import { Collection, Document } from 'firestorter';
import moment from 'moment';
import Slider from '@material-ui/lab/Slider';
import { DateTimePicker } from 'material-ui-pickers';
import { IconButton, InputAdornment, Icon } from '@material-ui/core';
import LeftChevron from '@material-ui/icons/ChevronLeftOutlined';
import RightChervon from '@material-ui/icons/ChevronRightOutlined';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import history from '../../history';

const bets = new Collection('bets');
// import Avatar from '@material-ui/core/Avatar';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Topic from '../Topic';

const styles = theme => ({
    root: {
        display: 'grid',
        // margin: theme.margin * 2,
        gridAutoFlow: 'rows',
        justifyContent: 'center center',
        height: 'calc(100vh - 60px)'
    },
    betHeader: {
        padding: 30,
        backgroundColor: theme.palette.primary.light,
        color: '#ffffff',
        'text-align': 'center'
    },
    betWager: {
        // backgroundColor: theme.palette.primary.dark
        'text-align': 'center',
        margin: '0 20px',

    },
    wager: {
        color: theme.palette.secondary.main
    },
    betExpire: {
        // backgroundColor: theme.palette.secondary.main
        'text-align': 'center',
        margin: '0 auto'
    },
    betSubmit: {
        // backgroundColor: theme.palette.secondary.light
        margin: '0 auto'
    }
});

interface DefaultProps {
    fb: {
        user: {            
            email: string;
            displayName: string;
            photoURL: string;
        },
    },
    match: {
        params: {
            id: string,
            index: string;
        }
    }
    classes: any,
    store: any,
}

const NewBet = observer(class TopicBet extends React.Component<DefaultProps, any> {

    state = {
        wager: 0,
        isDirty: false,
        selectedDate: moment().endOf('day'),
    }

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        HelperUtil.getDataByColDocId('topics', this.props.match.params.id);
    }

    checkBalance() {}

    setMaxBet() {}

    makeWager() {}

    handleAddBet = async (user) => {
        var me = this;
        const { docs } = HelperUtil.getDb('topics'); // @todo is this same instance of collection used for query?
        const { wager, selectedDate } = this.state;

        const doc = await bets.add(
          {
            owner: user.email || user.displayName,
            avatar: user.photoURL,
            details: docs.map(topic => topic.data.bets[this.props.match.params.index])[0],
            metadata: {
                topicRef: this.props.match.params.id,
                betIndex: this.props.match.params.index,
                title: docs.map(topic => topic.data.title)[0],
            },
            wager,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            expiresAt: moment.utc(selectedDate).format()
          }
        );

        if (doc && doc.id) {            
            HelperUtil.getUserData(user).then(async res => {
                const user = new Document(`users/${res.id}`);
                me.props.store.updateCoins(res.coins - wager);
                await user.update({
                    coins: res.coins - wager
                });     
                me.setState({ isDirty: true });
                history.push(`/bet/${doc.id}`);
            });
        }
        
    }


    handleChangeWager = (event, wager) => {
        event.preventDefault();
        this.setState({ wager });
    };

    handleDateChange = date => {
        this.setState({ selectedDate: date });
    };
    

    render() {
        const { fb : { user } } = this.props;
        const { selectedDate, wager, isDirty } = this.state;
        const { docs, isLoading } = HelperUtil.getDb('topics');

        const {classes} = this.props;
        return isLoading ? <CircularProgress /> :
        <div>
            {
                docs.map(doc => 
                    <div className={classes.root} key={doc.id}>
                        <div className={classes.betHeader}> 
                            <h2>{doc.data.title} </h2>
                            <h3 color="textSecondary">{doc.data.bets[this.props.match.params.index]}</h3>
                        </div>
                        <div className={classes.betWager}>
                        <h4 color="textPrimary">How much are you betting?</h4>
                        <h2 className={classes.wager}>${wager}</h2>
                        <Slider
                            classes={{ container: classes.slider }}
                            value={wager}
                            min={0}
                            max={100}
                            step={1}
                            onChange={this.handleChangeWager} />
                        </div>
                        <Divider />
                        <div className={classes.betExpire}>
                        <h4 color="textPrimary">When does this bet expire?</h4>
                        <DateTimePicker
                        autoOk
                        ampm={true}
                        showTabs={true}
                        autoSubmit={true}
                        allowKeyboardControl={true}
                        // disableFuture
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        helperText=""
                        leftArrowIcon={<LeftChevron />}
                        rightArrowIcon={<RightChervon />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <CalendarIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      </div>
                      <Divider />
                      <div className={classes.betSubmit}>
                          <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={this.handleAddBet.bind(this, user)} disabled={isDirty}>
                            submit bet
                            {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                            {/* <Icon className={classes.rightIcon}>send</Icon> */}
                        </Button>
                        </div>

                    </div>                     
          
                )
            }
        </div>;
        
    }

});

export default withFirebase(withStyles(styles, { withTheme: true })(NewBet));

import { Collection } from 'firestorter';
import firebase from '../Firebase';
import moment from 'moment';

const users = new Collection('users');
const bets = new Collection('bets');
const topics = new Collection('topics');

/*  @todo move into it's own helper or rename this as a firestore helper
 *  Firestore handlers
 */
export const getDb = (collection) => {
    switch (collection) {
        case 'users': return users;
        case 'bets': return bets;
        case 'topics': return topics;
        default: 
            return {};
    }
}
export const resetDb = (collection) => {
    getDb(collection).query = undefined;
};

export const hasExpired = (ts) => {
    console.log('ts =>', ts);
    console.log('now =>', new Date());
    console.log('moment now =>', moment.now);
    console.log('ts mom format =>', moment.utc(ts).format());
    return moment(ts).isBefore(moment());
}

export const getDataByColDocId = (collection, documentId) => {
    getDb(collection).query = ref => ref.where(firebase.firestore.FieldPath.documentId(),'==', documentId);
    return getDb(collection);
}

/*
 * Create database entry based on user login credentials if it doesn't exist
 * @todo switch where clause from email to displayName, phone or other credentials based on oauth type
 */
export const addGoogleUser = (user) => {
    users.query = (ref) => ref.where('email', '==', user.email)
        .get()
        .then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
                users.add(Object.assign({}, user, { coins: 1000 }, { createdAt: firebase.firestore.FieldValue.serverTimestamp() }));
            }
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
};

export const addTwitterUser = (user) => {
    users.query = (ref) => ref.where('displayName', '==', user.displayName)
        .get()
        .then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
                users.add(Object.assign({}, user, { coins: 1000 }, { createdAt: firebase.firestore.FieldValue.serverTimestamp() }));
            }
            // @todo update lastLoginAt if query returns a single value
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
};

// @wip instead of tracking a user collection, trying to calculate the currency wagered dynamically instead
export const getUserData = (user) => {
    return new Promise((resolve, reject) => {
        users.query = (ref) => ref.where('email', '==', user.email).limit(1)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach(doc => {
                resolve(Object.assign({}, { id: doc.id }, doc.data()));
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
            reject(error);
        });
    });
}

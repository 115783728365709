import * as React from "react";

import firebase from "./Firebase";

const defaultFirebaseContext = {
    authStatusReported: false,
    isUserSignedIn: false,
    user: {}
};

export const FirebaseAuthContext = React.createContext(defaultFirebaseContext);

type FireAuthProviderProps = {
    children: React.ReactNode
};

type FireAuthProviderState = {
    authStatusReported: boolean,
    isUserSignedIn: boolean,
    user: any
};

export default class FirebaseAuthProvider extends React.Component<FireAuthProviderProps, FireAuthProviderState> {

    state = defaultFirebaseContext;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            // console.log('fbauthprovider =>', user);
            this.setState({
                authStatusReported: true,
                isUserSignedIn: !!user,
                user: user
            }
        )});
    }

    render(): React.ReactNode {
        const {children} = this.props;
        const {authStatusReported, isUserSignedIn, user} = this.state;
        return (
            <FirebaseAuthContext.Provider value={{isUserSignedIn, authStatusReported, user}}>
                {authStatusReported && children}
            </FirebaseAuthContext.Provider>
        );
    }
}
import React from 'react';
import { FirebaseAuthContext } from '../../FirebaseAuthProvider';
import NavBar from './NavBar';

const Navigation = (props) => (
  <FirebaseAuthContext.Consumer>
    { context => {
      return (<NavBar fb={context} {...props} />)
    }}
  </FirebaseAuthContext.Consumer>  
);

export default Navigation;
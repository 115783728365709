import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import history from '../../history';

const styles = {
    fabButton: {
        position: 'absolute',
        bottom: 10,
        right: 10
    },
};

class BottomAppBar extends React.Component {

    render() {
        const { classes, isMobile } = this.props;
        return (
            isMobile && (history.location.pathname.indexOf('new') === -1) &&
            <div>
                <Fab 
                    color="secondary"
                    aria-label="Add"
                    className={classes.fabButton}
                    component={Link}
                    to='/topics'
                >
                    <AddIcon />
                </Fab>
            </div>            
        );
    }
}

export default withStyles(styles)(BottomAppBar);

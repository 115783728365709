import React from 'react'
import { Collection} from 'firestorter';
import {observer} from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  removeUnderline: {
    textDecoration: 'none'
  }
};

const UserProfile = observer(class MyProfile extends React.Component {

  constructor() {
    super();
    this.col = new Collection('users');
  }
 
  render() {
    const { classes } = this.props;

    const { docs, fetch } =  this.col;
    return fetch && <div className={classes.removeUnderline}>
      <h3>User Profile</h3>
        <div>{ fetch && JSON.stringify(docs.data) }</div>
      </div>
  }

});

export default withStyles(styles)(UserProfile);

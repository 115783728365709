import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { auth } from '../../Firebase';

import history from '../../history';
import { getUserData } from '../../helpers/HelperUtil';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import ListIcon from '@material-ui/icons/ListAltRounded';
import Chip from '@material-ui/core/Chip';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyOutlined';

import { observer } from 'mobx-react';
import { UserStore } from '../../UserStore';

const styles = theme => ({
    removeUnderline: {
        textDecoration: 'none'
    },
    root: {
        flexGrow: 1,
        backgroundColor: 'pink'
    },
    accountProfileMenu: {
        display: 'flex',
        flex: '1 0 auto',
        "flex-direction": 'column',
        alignItems: 'flex-end',
        // gridColumnStart: 3,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        gridColumnStart: 1,
    },
    foobar: {
        backgroundColor: theme.palette.error.dark
    },
    toolbar: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        justifyItems: 'center',
    },
    chip: {
        margin: theme.spacing.unit,
        // gridColumnStart: 3,
    },
    chipSpacing: {
        paddingLeft: '5px'
    },
    branding: {
        gridColumnStart: 2,
    },
    // desktopFab: {
    //     gridColumnStart: 3,
    // },
    toolbarRight: {
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center'
    }
});

interface DefaultProps {
    fb: any;
    classes: any;
    isMobile: boolean;
    store: UserStore;
}

@observer
class NavBar extends React.Component<DefaultProps, any> {

    constructor(props) {
        super(props);
        this.handleSignout = this.handleSignout.bind(this);
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        isUserSignedIn: !!this.props.fb.isUserSignedIn,
        anchorEl: null,
        left: false,
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleChange = event => {
        this.setState({ auth: event.target.checked });
    };

    handleDelete = event => {

    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleSignout = (e) => {
        console.log('handling signout =>', e);

        auth.signOut().then(() => {
            console.log('logged out successfully');
        }).catch(err => console.log('error signing out =>', err));
    }

    routeTo = path => () => {
        history.push(path);
    }
    
    componentDidMount() {
        const { fb : { user }, store } = this.props;

        getUserData(user).then(result => {
            // console.log('get user darta =>', result);
            store && store.updateCoins(result.coins);
            // this.setState({ coins: result.coins });
        });
    }

    render() {
        const { fb : { user }, classes, isMobile } = this.props;
        const { isUserSignedIn, anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <AppBar position="fixed"> 
                    <Toolbar classes={{ root: classes.toolbar }}>
                       { (history.location.pathname !== '/') &&
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={() => history.goBack()}>
                            <LeftArrowIcon fontSize="large"  />
                        </IconButton>
                       }

                        {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.routeTo('/')}>
                            <HomeIcon fontSize="large"  />
                        </IconButton> */}
                        {/* <Typography variant="h6" color="inherit">
                            ${this.props.store && this.props.store.getCoins()}
                        </Typography> */}
                        <Typography variant="h6" color="inherit" onClick={this.routeTo('/')} className={classes.branding}>
                            betme
                        </Typography>
                        
                        <div className={classes.toolbarRight }>

                        {!isMobile && (history.location.pathname.indexOf('new') === -1) && // @todo typings for component to use Link to
                        <Fab variant="extended"
                            size="small"
                            aria-label="New"
                            color="secondary"
                            className={classes.desktopFab}
                            onClick={this.routeTo('/topics')}>                            
                            <ListIcon /> Play
                        </Fab>
                        }
                        <Chip label={this.props.store && this.props.store.getCoins()} icon={<AttachMoneyIcon />} classes={{ root: classes.chip, label: classes.chipSpacing }} clickable variant="outlined" color="secondary" />

                        {isUserSignedIn && (
                            <div className={classes.accountProfileMenu}>
                                <IconButton
                                    aria-owns={open ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <Avatar src={user && user.photoURL} />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={this.handleClose} disabled>Profile</MenuItem>
                                    <MenuItem onClick={this.handleClose} disabled>Settings</MenuItem>
                                    <MenuItem onClick={this.handleSignout}>Sign Out</MenuItem>
                                </Menu>
                            </div>
                        )}
                        </div>

                    </Toolbar>
                </AppBar>
            </div>
        );
    }

}

export default withStyles(styles)(NavBar);

import React from 'react';

import { Collection } from 'firestorter';
import { observer } from 'mobx-react';
import withFirebase from '../../WithFirebase';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';

import './topic.css';
import history from '../../history';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as HelperUtil from '../../helpers/HelperUtil';

interface DefaultProps {
    // user: any,
    match: {
        params: {
            id: string
        }
    },
    fb: any,
    store: any,
}

const Topic = observer(class TopicBet extends React.Component<DefaultProps, any> {

    constructor(props) {
        super(props);
        this.handleItemSelected = this.handleItemSelected.bind(this);
    }
    
    handleItemSelected = e => {
        console.log(e, e.target, e.target.innerText);
        // HelperUtil.resetDb('topics');
        history.push(e.target.innerText.trim());        
    }

    componentDidMount() {
        HelperUtil.getDataByColDocId('topics', this.props.match.params.id);
    }

    render() {
        // const { fb : { user } } = this.props;
        const { docs, isLoading } = HelperUtil.getDb('topics');
    
        return (         
            <List>
            { isLoading ? <CircularProgress /> : 
            docs.map((doc) => 
                doc.data.bets.map((bet,index) =>
                <div key={index}>
                <Link to={`/new/${doc.id}/${index}`}>
                    <ListItem button>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={bet}/>
                    <ListItemIcon>
                        <RightArrowIcon />
                    </ListItemIcon>
                    </ListItem>
                    <Divider/>  
                </Link>
                </ div>
                )
            )} 
            </List>                 
        );
        
    }
});

export default withFirebase(Topic);

import * as React from "react";
import * as FirebaseAuthProvider from './FirebaseAuthProvider';
import Login from './components/Login';

const withAuth = (WrapperComponent) => class AppAuth extends React.PureComponent<any, any> {

    render(): React.ReactNode {
        return (
            <FirebaseAuthProvider.FirebaseAuthContext.Consumer>
                {
                    ({isUserSignedIn}) => {
                        if (isUserSignedIn) {
                            return <WrapperComponent {...this.props} />;
                        }
                        // return 
                        return <Login />
                    }
                }
            </FirebaseAuthProvider.FirebaseAuthContext.Consumer>
        );
    }
  };

  export default withAuth;
  
import firebase from 'firebase';
import 'firebase/firestore';

import {initFirestorter } from 'firestorter';

var config = {
  apiKey: 'AIzaSyB_0G3sMgGIdsU7fugushzutR7POATDhi0',
  // authDomain: 'betme-540f2.firebaseapp.com',
  authDomain: 'cmonbet.me',
  databaseURL: 'https://betme-540f2.firebaseio.com',
  projectId: 'betme-540f2',
  storageBucket: 'betme-540f2.appspot.com',
  messagingSenderId: '755786822959'
};

firebase.initializeApp(config);

// Initialize `firestorter`
initFirestorter({firebase: firebase});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const auth = firebase.auth();

export default firebase;
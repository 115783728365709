import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import Bet from './components/Bet';
import BetList from './components/BetList';
import UserProfile from './components/UserProfile';
import Topics from './components/Topics';
import Topic from './components/Topic';
import NewBet from './components/NewBet';
import './App.css';
  
const Routes = class PageRoutes extends React.Component {

  render() {
    const { store, isMobile } = this.props;
    
    const AddNewBet = (props) => {
      return (
        <NewBet {...props} store={store} />
      );
    }

    const UpdateBet = (props) => {
      return (
        <Bet {...props} store={store} isMobile={isMobile} />
      );
    }
    
    return <main className='grid-main-content'>
      <Switch>
        <Route exact path='/' component={BetList}/>
        <Route exact path='/bets' component={BetList} />
        <Route exact path='/bet/:id' component={UpdateBet} />
        <Route exact path='/profile' component={UserProfile} />
        <Route exact path='/topics' component={Topics} />
        <Route exact path='/topic/:id' component={Topic} />
        <Route exact path='/new/:id/:index' render={AddNewBet} />
        <Redirect to="/" />
      </Switch>
    </main>
  }
}

export default Routes;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles/*, Avatar */ } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

// import { DateTimePicker } from 'material-ui-pickers';
import { IconButton, InputAdornment } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

// import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';

import CloseIcon from '@material-ui/icons/Close';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import Divider from '@material-ui/core/Divider';

// Material UI Form components - ie. TextField composed of these
// import moment from 'moment';

import { Collection } from 'firestorter';
import { observer } from 'mobx-react';
import withFirebase from '../../WithFirebase';
// import firebase from '../../Firebase';

import history from '../../history';
// import * as HelperUtil from '../../helpers/HelperUtil';

import { Link } from 'react-router-dom';

const styles = {
  flex: {
    flex: 1
  },
};

const topics = new Collection('topics');

const Topics = observer(class TopicList extends React.Component<any, any> {

  static propTypes = {
    classes: PropTypes.object
  };

  // handleFocus = input => {
  //   input.target.focus();
  // };


  constructor(props) {
    super(props);
  }

  handleClose = () => {
    history.goBack();
  };

  resetData = () => {
    this.setState( { details : '' });
  }

  render() {
    const { classes } = this.props;
    const { docs, isLoading } = topics;

    return (
      
          <List>
          { isLoading ? <CircularProgress /> : 
            docs.map((doc) => 
            doc.data.status === 'open' && 
            <div key={doc.id}>
            <Link to={`topic/${doc.id}`}>
              <ListItem button>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText inset primary={doc.data.title} secondary={`${doc.data && doc.data.bets && doc.data.bets.length} active bets`}/>
                <ListItemIcon>
                  <RightArrowIcon />
                </ListItemIcon>
              </ListItem>
              <Divider/>  
            </Link>
            </div>
          )}
          </List>                 
    );
  }
});

export default withFirebase(withStyles(styles)(Topics));

import React from 'react';
import { auth, googleProvider, twitterProvider /*, facebookProvider */ } from '../../Firebase';
import Button from '@material-ui/core/Button';
import history from '../../history';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AtmIcon from '@material-ui/icons/LocalAtm';

const styles = {
    grid: {
        display: 'grid',
        height: '100vh',
        gridAutoFlow: 'row dense',
        justifyItems: 'center',
        alignContent: 'center',
        gridGap: '20px',
        backgroundColor: '#303249',
        color: '#ffffff'
    },
    largeIcon: {
        height: '128px',
        width: '128px',
        color: '#ffffff'
    },
    logo: {
        color: '#ffa500'
    }
};

class Login extends React.Component<any, any> {
    
    static propTypes = {
        classes: PropTypes.object.isRequired
    };    

    constructor(props) {
        super(props);
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
        this.handleTwitterLogin = this.handleTwitterLogin.bind(this);
        // this.handleFacebookLogin = this.handleFacebookLogin.bind(this);
    }

    handleGoogleLogin() {
        try {
            auth.signInWithRedirect(googleProvider);
        } catch (e) {
            console.log(e);
        }
    }

    handleTwitterLogin() {
        try {
            auth.signInWithRedirect(twitterProvider);
        } catch (e) {
            console.log(e);
        }
    }

    // @todo Account Linking - https://firebase.google.com/docs/auth/web/account-linking
    // handleFacebookLogin() {
    //     try {
    //         auth.signInWithRedirect(facebookProvider)
    //             .catch(error => {
    //                 if (error.code === 'auth/account-exists-with-different-credential') {
    //                     console.log('ohahsdfha');
    //                 }
    //             });
    //     } catch (e) {
    //         console.log('throwing fb login error =>', e);
    //     }
    // }

    render() {
        const { classes } = this.props;

        return (
        <div className={classes.grid}>
            <h1 className={classes.logo}>betme</h1>
            <AtmIcon className={classes.largeIcon } fontSize='large'/>
            <Button variant="outlined" color="secondary" size="large" onClick={this.handleGoogleLogin}>
                Google Login
            </Button>
            <Button variant="outlined" color="secondary" size="large" onClick={this.handleTwitterLogin}>
                Twitter Login
            </Button>
            {/* <Button variant="contained" color="primary" onClick={this.handleFacebookLogin}>
                Facebook Login
            </Button> */}
        </div>
        );
        
    }
}

export default withStyles(styles)(Login);

import React from 'react';
import './App.css';
import Routes from './Routes';
import Navigation from './components/Navigation';
import withAuth from './WithAuth';
import BottomAppBar from './components/BottomAppBar';
import { auth } from './Firebase';
import * as HelperUtil from './helpers/HelperUtil';


const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|Blackberry/i.test(window.navigator.userAgent);
};

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}

const isInStandaloneMode = () => (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
  && ('standalone' in window.navigator) && (window.navigator.standalone);

class Conditional extends React.Component {

  render() {
      const { children, condition, ...restOfProps } = this.props;
      if (!this.props.condition) return null;
      return (
          <div {...restOfProps}>
              {this.props.children}
          </div>
      )
  }
}

class App extends React.Component {

  state = {
    showInstallMessage: false
  }

  installPrompt = null;

  installApp = async () => {
    if (!this.installPrompt) return false;
    this.installPrompt.prompt();
    let outcome = await this.installPrompt.userChoice;
    if (outcome.outcome === 'accepted'){
      console.log("App Installed")
    }
    else{
      console.log("App not installed");
    }
    // Remove the event reference
    this.installPrompt = null;
    // Hide the button
    this.setState({
      showInstallMessage: false
    });
  }

  componentDidMount() {

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function () {
    //     window.history.pushState(null, document.title,  window.location.href);
    // });

    console.log("Listening for Install prompt");

    // @see https://blog.anam.co/progressive-web-apps-with-create-react-app/
    window.addEventListener('beforeinstallprompt', (e) => {
      // For older browsers
      e.preventDefault();
      console.log("Install Prompt fired");
      this.installPrompt = e;

      // See if the app is already installed, in that case, do nothing
      if (isIos() && !isInStandaloneMode()) {
        // Set the state variable to make button visible
        this.setState({
          showInstallMessage: true
        });
      }        
    });

    // Add to users collection if this oauth profile doesnt exist
    auth.getRedirectResult().then((result) => {
      if (result && result.user) {
        const { user: { email, photoURL, displayName, phoneNumber }, additionalUserInfo : { providerId } } = result;
        const userProfile = {
          displayName,
          email,
          photoURL,
          phoneNumber,
          providerId
        };
        
        if (providerId === 'google.com') {
          HelperUtil.addGoogleUser(userProfile);
        } else if (providerId === 'twitter.com') {
          HelperUtil.addTwitterUser(userProfile);
        }
        
      }      
    }).catch((error) => {
      console.log(error);
    });

  }

  render() {
    const { showInstallMessage } = this.state;
    
    return <div>
      <Conditional condition={showInstallMessage}
        style={{ color: 'red' }}
        onClick={this.installApp}>
          Install As Application
      </Conditional>
      { Navigation({isMobile: isMobile(), store: this.props.store}) }
      <div className="grid-container">
        <div className="leftside"></div>
        <div className="center">
          <Routes store={this.props.store} isMobile={isMobile()} />
          <BottomAppBar isMobile={isMobile()} />
        </div>
        <div className="rightside"></div>
      </div>
    </div>
  }
}

export default withAuth(App);

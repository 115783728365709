import React from 'react';
import ReactDOM from 'react-dom';

import { Router } from "react-router-dom";
import history from './history';

import './index.css';
import App from './App';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import * as serviceWorker from './serviceWorker';

import FirebaseAuthProvider from './FirebaseAuthProvider';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { green, blue } from '@material-ui/core/colors';
import { observable } from 'mobx';

class UserStore {
    @observable coins;

    constructor() {
        this.coins = 0;
    }

    getCoins() {
        return this.coins;
    }

    updateCoins(value) {
        this.coins = value;
    }
}
const store = new UserStore();

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        type: 'light',
        primary: {
            main: '#1C2541',
            light: '#1C2541',
            contrastText: '#ffffff',
            dark: '#3A506B'
        },
        secondary:  {
            main: '#5BC0BE',
            light: '#6FFFE9',
            contrastText: '#303249'
        },
        text: {
            primary: '#1C2541',
            secondary: '#ffa500'
        }
    }
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Router history={history}>
            <FirebaseAuthProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <App store={store} />
                </MuiPickersUtilsProvider>
            </FirebaseAuthProvider>
        </Router>
    </MuiThemeProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

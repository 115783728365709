import React from 'react';

import { Document } from 'firestorter';
import { observer } from 'mobx-react';
import withFirebase from '../../WithFirebase';
import * as HelperUtil from '../../helpers/HelperUtil';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';

import firebase from '../../Firebase';


// @helpful https://stackoverflow.com/questions/47831741/property-share-does-not-exist-on-type-navigator
let newVariable: any;
newVariable = window.navigator;


const styles = theme => ({
    root: {
        display: 'grid',
        placeItems: 'center'
    },
    card: {
      maxWidth: 600,
      width: 500,
      boxShadow: 'none'
    },
    cardMobile: {
        width: 340,
        maxWidth: 440,
        boxShadow: 'none'
    },
    userProfile: {
        alignSelf: 'center',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[200],
    },
    largerTitle: {
        fontSize: 'regular',
    },
    expiry: {
        fontSize: 'x-small'
    },
    disclaimer: {
        'text-align': 'center',
        fontSize: 'smaller'
    },
    avatarGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridGapRow: '3px',
        placeContent: 'center',
        placeItems: 'center',
        minHeight: 200,
    }
  });

const Bet = observer(class BetDetails extends React.Component<any, any> {

    state = { expanded: false };

    handleExpandClick = () => {
      this.setState(state => ({ expanded: !state.expanded }));
    };
  
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        HelperUtil.getDataByColDocId('bets', this.props.match.params.id);
    }

    handleBetClicked = async (user, wager) => {
        var me = this;
        const { email, displayName, photoURL, providerData } = user;
        const providerId = providerData[0].providerId;
        const bet = new Document(`bets/${this.props.match.params.id}`);
        
        // const doc = HelperUtil.getDb('bets');
        // @todo check for integrity
        await bet.set({
            players: firebase.firestore.FieldValue.arrayUnion({
                email,
                displayName,
                photoURL,
                acceptedAt: moment.utc().format()
            })
        }, {merge: true});

        // @todo hacky in situations where twitter user has email, for most part my account doesn't
        HelperUtil.getDb('users').query = ref => ref.where(providerId === 'google.com' ? 'email': 'displayName','==', email || displayName)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    me.props.store.updateCoins(doc.data().coins - wager);
                    const user = new Document(`users/${doc.id}`);
                    user.update({
                        coins: doc.data().coins - wager,
                    }) 
                })
            });        
    }

    handleShareUrl = e => {
        console.log(e);
    }

    hasAlreadyBet = (user, doc) => {
        const { data : { players } } = doc;
        const didBet = players && (
                players.filter(i => i.displayName === user.displayName).length > 0 || // isn't part of the queue
                players.filter(i => i.email === user.email).length > 0
            );
        console.log('this user has betted? =>', didBet);
        return didBet;
    }

    handleWebShare = (doc) => {
        const { data : { details, metadata } } = doc;
        // newVariable.share({url: url});
        if (newVariable.share) {
            newVariable.share({
                title: metadata.title,
                text: details,
                url: document.location.href,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
    }

    render() {
        const { fb : { user }, classes, isMobile } = this.props;
        const { docs, isLoading } = HelperUtil.getDb('bets'); // @todo rename to getDbRef
              
        return isLoading ? <CircularProgress /> :
        docs.map(doc =>
            <div className={classes.root} key={doc.id}>
            <Card className={isMobile ? classes.cardMobile : classes.card}>
            <CardHeader
                classes={{ action: classes.userProfile, title: classes.largerTitle }}
              avatar={
                <Avatar aria-label="owner" src={doc.data.avatar} />                  
              }
              action={
                <Avatar aria-label="wager" className={classes.avatar}>
                  ${doc.data.wager}
                </Avatar>
              }
              title={doc.data.details}
              subheader={doc.data.metadata.title}
            />
            <div className={classes.avatarGrid}>
                {doc.data && doc.data.players && doc.data.players.map((tile, index) => (
                    <Avatar aria-label="participants" className={classes.avatar} src={tile.photoURL} key={index} />
                ))}
            </div>
            <CardContent>
              <Typography component="p" classes={{ root: classes.disclaimer }}>
              <em>Created {moment(moment.utc(doc.data.createdAt.seconds * 1000).format()).fromNow()}.  
              Close{`${moment(doc.data.expiresAt).isBefore(moment()) ? 'd ':' '}`}{moment(doc.data.expiresAt).fromNow()}.
              </em>
              </Typography>
            </CardContent>
            <CardActions className={classes.actions} disableActionSpacing>
              <IconButton aria-label="Favorite" color="secondary" disabled>
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="Share"
                    onClick={this.handleWebShare.bind(this, doc)}>
                <ShareIcon />
              </IconButton>            
                <div className={classnames(classes.expand)}>
                <Button variant="outlined" size="small" color="primary"
                    className={classes.margin}
                    onClick={this.handleBetClicked.bind(this, user, doc.data.wager)}
                    aria-label="Bet"
                    disabled={
                        ((user.email || user.displayName) === doc.data.owner) ||
                        (this.hasAlreadyBet(user, doc)) ||
                        (moment(doc.data.expiresAt).isBefore(moment()))
                    }    
                >
                    Bet
                </Button>
                </div>           
            </CardActions>
            </Card>
            </div>
        );
    }

});

export default withFirebase(withStyles(styles,{withTheme:true})(Bet));
